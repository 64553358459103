import React, { FC, memo, useEffect, useMemo } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Overlay, { OverlaySlotsProps } from '@/modules/player/components/player/Overlay'
import VideoStreamer, { VideoStreamerProps } from '@/modules/player/components/player/VideoStreamer'

import PlayerFullscreenManager, {
  PlayerFullscreenManagerProps,
} from '@/modules/player/Player/PlayerFullscreenManager'
import PlayerVideoManager, {
  PlayerVideoManagerProps,
} from '@/modules/player/Player/PlayerVideoManager'
import PlayerInteractionManager, {
  PlayerInteractionManagerProps,
} from '@/modules/player/Player/PlayerInteractionManager'
import PlayerKeyboardManager from '@/modules/player/Player/PlayerKeyboardManager'

import PlayerManager from '@/modules/player/player.manager'
import { PlayerIDProvider } from '@/modules/player/PlayerIDProvider'

import {
  FullscreenState,
  getDefaultState,
  playerReducer,
  PlayerReducerActions,
  PlayerState,
  ProgressState,
  ScreenState,
  VideoState,
} from '@/modules/player/player.reducer'
import LoaderForUnFullScreen from '@/modules/player/components/player/LoaderForUnFullScreen'
import dayjs from 'dayjs'

type ExcludeVideoProps =
  | 'onPlay'
  | 'onPause'
  | 'onStop'
  | 'onEnded'
  | 'onWaiting'
  | 'onCanPlay'
  | 'onTimeUpdate'
  | 'onSeeking'
  | 'onSeeked'
  | 'onError'

export interface PlayerProps {
  playerId: string
  hideOverlay?: boolean
  hidePlayer?: boolean
  showBufferLoaderInUnFullScreen?: boolean
  className?: string

  videoState?: Partial<VideoState>
  screenState?: Partial<ScreenState>
  progressState?: Partial<ProgressState>
  fullscreenState?: Partial<FullscreenState>

  videoManagerProps?: Partial<PlayerVideoManagerProps>
  fullScreenManagerProps?: Partial<PlayerFullscreenManagerProps>
  interactionManagerProps?: Partial<PlayerInteractionManagerProps>

  overlayProps?: Partial<OverlaySlotsProps>
  videoStreamerProps: Omit<VideoStreamerProps, ExcludeVideoProps>
}

const Player: FC<PlayerProps> = ({
  playerId,
  hideOverlay = false,
  hidePlayer = false,
  showBufferLoaderInUnFullScreen = false,
  className,
  progressState,
  videoState,
  screenState,
  fullscreenState,
  videoManagerProps,
  fullScreenManagerProps,
  interactionManagerProps,
  overlayProps,
  videoStreamerProps,
}) => {
  const { PlayerProvider } = useMemo(() => {
    return PlayerManager.createPlayer<PlayerState, PlayerReducerActions>(playerId, playerReducer)
  }, [])

  useEffect(() => {
    return () => {
      PlayerManager.disposePlayer(playerId)
    }
  }, [])

  const pl = useMemo(() => {
    return getDefaultState({ progressState, videoState, screenState, fullscreenState })
  }, [])
  return (
    <div id={playerId || 'player'} className={classNames(styles.Player, className)}>
      <PlayerProvider initialState={pl}>
        <PlayerIDProvider value={playerId}>
          <PlayerVideoManager
            progressState={progressState}
            videoState={videoState}
            {...videoManagerProps}
          >
            {({ onVideoRef, togglePlayPause, changeProgress }) => {
              return (
                <PlayerFullscreenManager {...fullScreenManagerProps}>
                  {({ onFullscreenRef, ...fullscreenState }) => {
                    return (
                      <PlayerInteractionManager {...interactionManagerProps}>
                        {({ handleMouseMove, handleKeyPressed, setUserActivity }) => {
                          return (
                            <PlayerKeyboardManager
                              onKeyPressed={handleKeyPressed}
                              togglePlayPause={togglePlayPause}
                            >
                              {() => {
                                return (
                                  <div
                                    ref={onFullscreenRef}
                                    onMouseMove={handleMouseMove}
                                    className={classNames(styles.Player__Video, {
                                      [styles.Winter]: [0,11].includes(dayjs().month())
                                    })}
                                  >
                                    {!hidePlayer && (
                                      <>
                                        <VideoStreamer
                                          {...videoStreamerProps}
                                          ref={onVideoRef}
                                          autoPlay={
                                            videoStreamerProps.autoPlay === undefined ||
                                            videoState?.isPaused
                                              ? false
                                              : videoStreamerProps.autoPlay
                                          }
                                        />
                                        {!hideOverlay && fullscreenState.isFullscreen && (
                                          <Overlay
                                            changeProgress={changeProgress}
                                            togglePlayPause={togglePlayPause}
                                            fullscreenState={fullscreenState}
                                            setUserActivity={setUserActivity}
                                            {...overlayProps}
                                          />
                                        )}
                                        {hideOverlay && showBufferLoaderInUnFullScreen && (
                                          <LoaderForUnFullScreen />
                                        )}
                                      </>
                                    )}
                                  </div>
                                )
                              }}
                            </PlayerKeyboardManager>
                          )
                        }}
                      </PlayerInteractionManager>
                    )
                  }}
                </PlayerFullscreenManager>
              )
            }}
          </PlayerVideoManager>
        </PlayerIDProvider>
      </PlayerProvider>
    </div>
  )
}

export default memo(Player)
